.main {
    width: 100vw;
    padding: 14px 0px;
    border-bottom: 1px solid var(--Grey-12, #1f1f1f);
    background: rgba(15, 15, 15, 0.6);
    backdrop-filter: blur(10px);
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 9;
}
.main_box {
    width: 1400px;
    margin: 0 auto;
}
.main_box_right {
    flex: -1;
    display: flex;
    align-items: center;
}
.main_box_right_item {
    color: #fff;
    font-size: 16px;
    margin-right: 2px;
}
.main_box_right_item_on {
    border-radius: 84px;
    border: 1px solid #484848;
    /* background: linear-gradient(to bottom right, #fff, rgba(15, 15, 15, 0.2), #fff) padding-box; */
}
.main_box_right_item_on_bg {
    padding: 10px 22px;
    border-radius: 84px;
    /* background: #1a1a1a; */
    cursor: pointer;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.main_box_wallet {
    border-radius: 84px;
    border: 1px solid #323232;
    background: var(--Grey-10, #1a1a1a);
    display: flex;
    padding: 10px 24px;
    align-items: flex-start;
    gap: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    cursor: pointer;
}

.ml_10 {
    margin-left: 2px;
}

/* 当屏幕宽度小于 600px 时 */
@media (max-width: 750px) {
    .main {
        display: none;
    }
}
