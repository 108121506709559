.pc {
    margin-top: -218px;
}
.pc_bg {
    height: 578px;
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../../assets/image/icon8.png');
    background-size: cover;
    z-index: -1;
}

/* 当屏幕宽度小于 600px 时 */
@media (max-width: 750px) {
    .pc {
        display: none;
    }
}

.h5 {
    margin-top: -148px;
}
.h5_bg {
    height: 218px;
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../../assets/image/icon8.png');
    background-size: cover;
    background-position: center;
    z-index: -1;
}
/* 当屏幕宽度大于等于 750px 时 */
@media (min-width: 750px) {
    .h5 {
        display: none;
    }
}
