.main {
    width: 100vw;
    padding: 12px 18px;
    background: #000000e6;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 4;
    touch-action: none;
    border-bottom: 1px solid #1f1f1f;
}
.main_box {
    margin: 0 auto;
}
.main_box_right {
    flex: -1;
    display: flex;
    align-items: center;
}
.main_box_right_item {
    color: #fff;
    font-size: 18px;
    margin-bottom: 8px;
}
.main_box_right_item_on {
    width: fit-content;
    padding: 1px;
    margin: 0 auto;
    border-radius: 84px;
    background: linear-gradient(to bottom right, #fff, rgba(15, 15, 15, 0.2), #fff) padding-box;
}
.main_box_right_item_on_bg {
    width: fit-content;
    margin: 0 auto;
    text-align: center;
    padding: 10px 22px;
    border-radius: 84px;
    cursor: pointer;
}
.main_box_right_item_on_bg2 {
    background: #232323;
}
.main_box_wallet {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    border-radius: 8px;
    border: 1px solid #323232;
    background: #1a1a1a;
    padding: 8px 11px;
    margin-left: 10px;
}
.ml_10 {
    margin-left: 10px;
}
.mr_10 {
    margin-right: 10px;
}
.main_box_box {
    width: 165px;
    border-radius: 20px;
    padding: 20px 0px;
    border: 1px solid #323232;
    background: #232323;
    backdrop-filter: blur(3px);
}

/* 当屏幕宽度大于等于 750px 时 */
@media (min-width: 750px) {
    .main {
        display: none;
    }
}
