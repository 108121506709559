/* @font-face {
    font-family: 'Inter';
    src: url('./assets/font/Inter-Bold-4.otf');
} */

body {
    font-family: 'Source Han Sans CN', '微软雅黑', 'Source Han Sans SC', '-apple-system', 'BlinkMacSystemFont',
        'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', 'Noto Sans', 'sans-serif', 'Apple Color Emoji',
        'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    background: #000;
}

body::-webkit-scrollbar {
    background-color: pink;
    width: 10px;
}

/*定义滚动条轨道 内阴影+圆角*/
body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px 6px rgba(0, 0, 0, 0.2);
    background-color: #ddd;
}

/* 去除所有浏览器中的数字输入框的箭头 */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
    appearance: textfield; /* 其他浏览器 */
}

.ant-modal-body {
    padding: 0px;
    background: none;
}
.ant-modal-content {
    background: none;
}

.text-truncate_1 {
    word-wrap: normal; /* for IE */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.text-truncate-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.text-truncate-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.text-truncate-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.pointer {
    cursor: pointer;
}

input,
input:focus,
select:focus,
textarea:focus {
    outline: -webkit-focus-ring-color auto 0;
    border: none;
    outline: none;
    border-radius: 0;
    box-shadow: none;
}
/* 移除 iOS 输入框聚焦时的蓝色边框 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-text-fill-color: inherit;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    transition: background-color 5000s ease-in-out 0s;
}

.w-1200 {
    width: 1200px;
    margin: 0 auto;
}
.flex {
    display: flex;
}
.flex-1 {
    flex: 1;
}

.text-left {
    text-align: left !important;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}

.transition_class {
    transition: all 0.12s ease-out; /* 过渡效果 */
}

.noData {
    text-align: center;
}

/* 去除输入框数字类型上下箭头 */
.no-spinner {
    -moz-appearance: textfield; /* Firefox */
    appearance: textfield; /* Other browsers */
}
/* 针对 WebKit 浏览器 */
.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.commitSoon {
    padding-top: 60px;
    text-align: center;
}
.commitSoon img {
    width: 200px;
}
.commitSoon span {
    display: block;
    color: #424242;
    font-size: 18px;
    font-weight: 700;
}

.horizontal-scroll-container {
    width: 100%; /* 容器宽度 */
    overflow-x: auto; /* 横向滚动条 */
    white-space: nowrap; /* 防止内容换行 */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
/* 动画旋转 */
.rotate-360 {
    animation: spin 2s linear infinite;
}

/* 旋转180度 */
.rotate-180 {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}
/* 旋转90度 */
.rotate-90 {
    transform: rotate(90deg);
    transition: transform 0.3s ease;
}
/* 旋转0度 */
.rotate-0 {
    transform: rotate(0deg);
    transition: transform 0.3s ease;
}

.ant-notification {
    top: 66px !important;
}
.ant-notification-notice {
    border-radius: 1.16667rem;
    border: 1px solid #2f2f2f;
    background: #fff;
}
.ant-notification-notice-message {
    font-size: 16px;
    /* color: #fff; */
    font-weight: 500;
}
/* .ant-notification-notice-close {
    color: #fff;
}
.ant-notification-notice-close:hover {
    color: #fff;
} */
.market_dropdown .ant-dropdown-menu {
    padding: 10px;
    background-color: #232323;
    border-radius: 20px;
    border: 1px solid #323232;
}
.market_dropdown .ant-dropdown-menu-item:hover {
    background-color: #232323;
    border-radius: 20px;
}
.market_dropdown .ant-dropdown-arrow:before {
    background: #232323;
}
.market_dropdown .ant-dropdown-menu-title-content {
    white-space: nowrap;
    text-align: center;
}

.idoSwiper {
    padding: 34px 0px !important;
    background: #000;
}
.idoSwiper .swiper-slide {
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transition: 300ms;
    transform: scale(0.6) !important;
}
.idoSwiper .swiper-slide-active,
.idoSwiper .swiper-slide-duplicate-active {
    transform: scale(1) !important;
    z-index: 2;
}
.idoSwiper .swiper-slide-next {
    z-index: inherit;
}

/* 当屏幕宽度小于 600px 时 */
@media (max-width: 750px) {
    .w-1200 {
        max-width: 100%;
        box-sizing: border-box;
    }
    .noData img {
        width: 160px;
        text-align: center;
    }
    .ant-notification {
        top: 54px !important;
    }
    .commitSoon {
        padding-top: 0px;
    }
    .commitSoon img {
        width: 120px;
    }
}
